<template>
    <div class="purchase-layout withTopBar">
        <van-nav-bar title="套餐购买" left-text="返回" left-arrow border safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
        <div class="purchase-form-box">
            <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">商品名称</div>
            <div class="flex_one">
              <van-field 
                class="wb100"
                readonly
                v-model="curProduct.title"
                placeholder="请输入商品名称"
              />
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14 pdt10 pdb10">
            <div class="label wd60 color_gray_two">总瓶数</div>
            <div class="flex_one pdl15 flex_box align_center" @click="showPopup('size')">
              <div class="flex_one">共{{curSize.total_num}}瓶 各{{curSize.single}}瓶</div>
              <div class="iconfont icon-right font16 pdl5"></div>
            </div>
          </div>
          <div class="form-item flex_box align_center holder font14 pdt10 pdb10">
            <div class="label wd60 color_gray_two">套餐金额</div>
            <div class="flex_one pdl15">{{curSize.dis_price}}元</div>
          </div>
          <div class="form-item flex_box align_center holder font14">
            <div class="label wd60 color_gray_two">配送地址</div>
            <div class="flex_one flex_box align_center space_between" @click="selectAddress">
              <van-field 
                v-if="!curAddress.id"
                class="flex_one"
                disabled
                placeholder="请选择配送地址"
              />
              <div class="pdt10 pdb10 pdl15" v-else>{{curAddress.address_str}}</div>
              <div class="iconfont icon-right font16 pdl5"></div>
            </div>
          </div>
          <!--<div class="form-item flex_box align_center holder font14 pdt10 pdb10">
            <div class="label wd60 color_gray_two">奶箱押金</div>
            <div class="flex_one pdl15">{{curAddress.deposit ? curAddress.deposit : 0.00}}元</div>
          </div>-->
          <div class="form-item flex_box align_center holder font14 pdt10 pdb10">
            <div class="label wd60 color_gray_two">合计金额</div>
            <div class="flex_one pdl15">{{curSize.dis_price}}元</div>
          </div>
          <div class="sub_btn text_center" @click="goOrder">确定并支付</div>
        </div>
        <van-popup v-model="popupFlag" :closeable="popupType=='goOrder'"
                   position="bottom" close-on-popstate safe-area-inset-bottom>
          <van-picker v-show="popupType=='size'" show-toolbar title="套餐规格" :columns="curProduct.price_list" :default-index="sizeIndex" value-key="priceText" @confirm="sizeConfirm" @cancel="onCancel" />
          <div class="comfirm-box" v-show="popupType=='goOrder'">
            <div class="text_center font22 mb10">订单支付</div>
            <div class="text_center font20 mb10 color_gray_two">订单金额：<span class="color_red">{{curSize.dis_price}}元</span></div>
            <!--<div class="pay-item flex_box align_center space_between" @click="pay_way='2'">
              <div class="flex_one flex_box align_center">
                <div class="iconfont icon-zhifubao font24 color_zfb mr15"></div>
                <span class="font16">支付宝付款</span>
              </div>
              <van-icon name="checked" class="font18 color_theme" v-show="pay_way=='2'" />
            </div>-->
            <div class="pay-item flex_box align_center space_between" @click="pay_way='1'">
              <div class="flex_one flex_box align_center">
                <div class="iconfont icon-weixin font24 color_wx mr15"></div>
                <span class="font16">微信付款</span>
              </div>
              <van-icon name="checked" class="font18 color_theme" v-show="pay_way=='1'" />
            </div>
            <div class="pay-item flex_box align_center space_between" @click="pay_way='3'">
              <div class="flex_one flex_box align_center">
                <div class="iconfont icon-daohang2 font24 color_yellow mr15"></div>
                <span class="font16">线下付款</span>
              </div>
              <van-icon name="checked" class="font18 color_theme" v-show="pay_way=='3'" />
            </div>
            <div class="pay_btn mt15" @click="goCreateOreder">支付</div>
          </div>
        </van-popup>
        <van-popup v-model="showCode" :close-on-click-overlay="false" closeable @click-close-icon="handleClosed">
          <div class="qrcode-box">
            <div class="text_center mb10 font16">请扫码支付</div>
            <div id="qrcode" ref="qrcode"></div>
            <div class="flex_box align_center mt10">
              <span class="mr10 font15">支付剩余时间：</span>
              <div class="">
                <van-count-down :time="time" @finish="finish">
                  <template #default="timeData">
                    <span class="block">{{ timeData.minutes }}</span>
                    <span class="colon">:</span>
                    <span class="block">{{ timeData.seconds }}</span>
                  </template>
                </van-count-down>
              </div>
            </div>
          </div>
        </van-popup>
    </div>
</template>

<script>
import { mapState } from "vuex";
import QRCode from "qrcodejs2";
export default {
    name:"pre-storage",
    data(){
        return{
            curProduct:{
                id:"",
                title:"",
                selfprice:""
            },
            sizeIndex:0,
            curSize:{
              single:0,
              total_num:0
            },
            popupFlag:false,
            popupType:"",
            curAddress:{},
            pay_way:1,//付款方式 1微信，2支付宝，3线下
            showCode:false,
            payUrl:"",//支付链接weixin://wxpay/bizpayurl?pr=vZjjA5kzz
            time: 5*60*1000,
            timeData:{
              minutes:5,
              seconds:0
            },
            timer:null
        }
    },
    created(){
        this.curProduct = JSON.parse(JSON.stringify(this.product))
        for(let item of this.curProduct.price_list){
          item.priceText = '共'+item.total_num+'瓶  各'+item.single+'瓶'
        }
        let index = this.curProduct.price_list.findIndex(item => item.total_num == this.curProduct.total_num)
        if(index >= 0){
          this.curSize = this.curProduct.price_list[index] 
          this.sizeIndex = index
        }
        console.log(this.curProduct,this.curSize)
        if(this.shifUser.id){
          this.curAddress = JSON.parse(JSON.stringify(this.shifUser))
          //this.$store.commit('setShifUser', {})
        }
    },
    methods:{
        goOrder(){
          if(this.formCheck()){
            this.showPopup('goOrder')
          }else{
            this.$toast.fail('选择配送地址');
          }
        },
        goCreateOreder(){
          if(this.pay_way!=''){
            const _this = this 
            _this.$api.common.buyDiscountOrder({
              dis_id:_this.curProduct.dis_id,//产品id
              single:_this.curSize.single,//单次数量
              address_id:_this.curAddress.id,
              pay_way:_this.pay_way
            }).then(res=>{
              if(res.code =="0" && res.data){
                _this.payUrl = res.data.code_url
                _this.popupFlag = false
                _this.showCode = true
                _this.CheckPaySate(res.data.order_id)
                _this.$nextTick(() => {
                    _this.qrcode()
                })
              }
            })
            
          }else{
            this.$toast.fail('选择支付方式');
          }
        },
        CheckPaySate(orderId){
          const _this = this
          this.timer=setInterval(()=>{
              _this.$api.common.getDisOrderPayState({
                  dis_order_id:orderId
              }).then(res=>{
                  if(res.code =="0" && res.data && res.data.pay_state == 1){
                      _this.clearTimer()
                      _this.showCode = false
                      _this.$toast.success('支付成功')
                      _this.jumpList()
                  }
              })
          },5000)
        },
        handleClosed(){
          this.$toast.fail('支付取消')
          this.jumpList()
        },
        jumpList(){
          const _this = this
          setTimeout(()=>{
            _this.$router.push({
              name: "pre-storage"
            })
          },1500)
        },
        selectAddress(){
          this.$router.push({
              name: "user-list",
              query:{
                type:'select',
                preName:"package-purchase"
              }
          })
        },
        finish(){
          this.$toast.fail('支付超时')
          this.showCode = false
          this.jumpList()
        },
        qrcode() {
            const _this = this
            new QRCode("qrcode", {
                width: 300, // 二维码宽度，单位像素
                height: 300, // 二维码高度，单位像素
                text: _this.payUrl// 生成二维码的链接
            })
        },
        sizeConfirm(obj,index){
          console.log(obj,index)
          this.curSize = obj
          this.sizeIndex = index
          this.onCancel()
        },
        onCancel(){
          this.popupFlag =false
          this.popupType = ""
        },
        showPopup(type){
          this.popupFlag =true
          this.popupType = type
        },
        formCheck(){
          if(!this.curAddress.id){
            return false
          }
          return true
        },
        clearTimer(){
            this.timer ? clearInterval(this.timer) : '' //关闭
        },
        onClickLeft(){
            this.$router.push({
                name: "product-list"
            })
        }
    },
    computed:{
      ...mapState(["product","shifUser"]),
      countMoney(){
        return this.curSize.dis_price * this.curSize.total_num
      }
    },
    beforeDestroy(){
      console.log(1111)
      this.clearTimer()
      this.$toast.clear()
    }
}
</script>

<style lang="scss">
@import "./package-purchase.scss"
</style>

